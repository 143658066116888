<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Viewings</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.viewingDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Viewing</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="viewingTableHeaders"
              :items="property.viewings"
              show-expand
              no-data-text="No Viewings found"
            >
              <template v-slot:item.enquiry="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-customers-individual',
                    params: { customerId: item.enquiry.customer.id },
                  }"
                  >{{ item.enquiry.customer.full_name }}</router-link
                >
              </template>
              <template v-slot:item.feedback="{ item }">
                <v-chip label small color="success" v-if="item.feedback"
                  >Yes</v-chip
                >
                <v-chip label small color="error" v-else>No</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.viewingDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="$refs.deleteViewingDialog.openDelete(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <viewing-dialog ref="viewingDialog" :editingFromProperty="true" />
    <delete-viewing-dialog
      ref="deleteViewingDialog"
      :deletefromProperty="true"
    />
  </div>
</template>

<script>
import ViewingDialog from "./viewings/components/ViewingDialog.vue";
import DeleteViewingDialog from "./viewings/components/DeleteViewingDialog.vue";

export default {
  components: {
    ViewingDialog,
    DeleteViewingDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
      viewingTableHeaders: [
        { text: "Viewing Date", value: "formatted_dates.viewing_date" },
        { text: "Customer", value: "enquiry" },
        { text: "Performed By?", value: "viewing_performed_by" },
        { text: "Status", value: "status" },
        { text: "Follow Up Date", value: "formatted_dates.follow_up_date" },
        { text: "Feedback Received?", value: "feedback" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
